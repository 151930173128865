.instruction-close-icon:hover {
  color: rgb(92, 92, 92);
}

.instruction-text-content::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
/* For tablet-sized screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .stack-space-alerts {
    display: flex; /* Ensure elements are displayed as a flex container */
    flex-wrap: nowrap; /* Prevent wrapping */
    align-items: center; /* Align items vertically */
  }

  .info-name,
  .info-description,
  .info-description-percentage,
  .info-timestamp {
    flex: 1; /* Allow elements to grow and shrink to fit the container */
  }

  .info-timestamp {
    text-align: left; /* Align timestamp text to the left */
    margin-left: auto; /* Push timestamp to the rightmost side of the container */
  }
}

@media screen and (min-width: 663px) and (max-width: 767px) and (orientation: landscape) {
  .info-availability-carousel {
    text-align: center !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .info-availability-carousel {
    text-align: center !important;
  }

  .info-name-carousel {
    text-align: center !important;
  }
}

@media screen and (min-width: 520px) and (max-width: 767px) and (orientation: portrait) {
  .info-availability-carousel {
    text-align: center !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .stack-space-alerts {
    display: flex; /* Ensure elements are displayed as a flex container */
    flex-wrap: nowrap; /* Prevent wrapping */
    align-items: center; /* Align items vertically */
  }

  .info-name,
  .info-description,
  .info-description-percentage,
  .info-timestamp {
    flex: 1; /* Allow elements to grow and shrink to fit the container */
  }

  .info-timestamp {
    text-align: left; /* Align timestamp text to the left */
    margin-left: auto; /* Push timestamp to the rightmost side of the container */
  }
}
