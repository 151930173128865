.save-button {
  font-size: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  width: 6rem;
  height: 2.5rem;
  /* box-shadow: 0px 4px 4px 0px rgba(0.25, 0, 0, 0.25); */
  text-align: center;
  font-weight: 400;
  border: none;
  border-radius: 10px;
  position: relative;
  background: #86bc25;
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.1);
  color: #7e898e;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
}

.save-button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  border: none;
  border-radius: 10px;
  opacity: 0;
  content: '';
  background: #6fae1e;
  z-index: -1;
}

.save-button:hover,
.save-button:focus {
  color: white;
}

.save-button:hover:before,
.save-button:focus:before {
  transition: 0.3s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.save-button:active {
  transform: scale(0.9);
}
