.verticalShadowLine {
  background: var(--body_color);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  width: 889.999px;
  height: var(--subscription-modal-vertical-line-height);
}
.description-label {
  font-size: 14px !important;
  color: #8c8f91 !important;
  width: 90%;
}

.modal-modal-description {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: var(--body_color) !important;
  margin-top: 20px !important;
}

.subscription-modal {
  background-color: var(--body_background) !important;
  box-shadow: var(--subscription-modal-box-shadow) !important;
}

.MuiModal-backdrop {
  backdrop-filter: blur(3px) !important;
}

.cancel-update-container-mobile {
  display: flex;
  gap: 2em;
  width: 100%;
  flex-direction: row;
  min-height: 50px;
  justify-content: space-around;
  align-items: center;
}

.confirmation-dialog-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.confirmation-dialog-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1em; */
}
.save-button[disabled] {
  opacity: 0.6; /* Reduce opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
}

.leftLabel-mobile {
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: var(--body_color) !important;
}

.leftLabel-mobile-smaller-font {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: var(--body_color) !important;
}

.reusable-dropdown-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;
  gap: 0.5rem;
}

.reusable-dropdown-left-label-container-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.reusable-dropdown-right-dropdown-container-mobile {
  display: flex;
  align-items: center;
  width: 100% !important;
}
.subscription-modal-main-container::-webkit-scrollbar {
  width: 4px !important;
}

.info-icon-alerts-modal {
  margin-left: 3px;
  width: 20px !important;
  color: #8c8585 !important;
  cursor: pointer !important;
}

.info-icon-alerts-modal:hover {
  color: rgb(102, 102, 102) !important;
}

.subscription-modal-main-container {
  display: flex;
  flex-direction: column;
  scrollbar-width: thin !important;
}

.subscription-modal-welcome-msg-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--body_color) !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}

/* .MuiAutocomplete-root{
    max-height: 150px !important;
    overflow-y: scroll !important;
} */

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .css-1uai4b8 {
    max-width: 768px !important;
  }
}
