.Outside_wrapper {
  min-height: 100vh;
  display: flex;
  /* width: 100%; */
  flex-direction: column;
}

.welcome-container-center {
  text-align: center !important;
  padding-top: 20vh !important;
}

.home-container {
  padding: 0rem 6rem;
}

.welcome-text {
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  color: hsl(0, 0%, 56%);
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !important;
  letter-spacing: 1px !important;
  overflow: hidden;
  border-right: 2px solid hsl(0, 0%, 80%);
  white-space: nowrap;
  animation:
    typewriter 3s steps(44) 1s 1 normal both,
    blinkTextCursor 1500ms infinite;
}

.homePageContainer {
  padding: 0px !important;
  overflow: hidden;
}

.loginContainer {
  display: flex !important;
  height: 100vh !important;
  flex-direction: row !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden !important;
  margin: none !important;
}
.label-button-container {
  gap: 12px;

  display: flex;
  flex-direction: column;
}

.login-button {
  /* border: 1px solid #ffffff !important; */
  /* opacity: 0.2 !important; */
  /* color: white !important; */
  width: 14rem rem !important;
  text-transform: unset !important;
  border-radius: 4px !important;
  border: 1px solid #fff !important;
  background: #d9d9d9 !important;
  color: #292929 !important;
}
.login-button:hover {
  color: #4b4545 !important;
}

.welcomeText {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  width: 12em;
  color: #ffffff;
}

.loginPart {
  background: #008080;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 30%;
}

.backgroundPicturePart {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-color: rgb(0, 0, 0);
  background-size: contain;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinningTest {
  animation: loading 110s linear infinite;
  height: 39rem;
}

.sign-in-btn-home {
  text-align: -webkit-center;
}

.sign-in-btn-home button {
  --primary-color: hsl(0, 0%, 56%);
  --hovered-color: hsl(81deg 97% 41%);
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.sign-in-btn-home button p {
  margin: 0;
  position: relative;
  font-size: 25px;
  color: var(--primary-color);
}

.sign-in-btn-home button::after {
  position: absolute;
  content: '';
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.sign-in-btn-home button p::before {
  position: absolute;
  /*   box-sizing: border-box; */
  content: 'Login';
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.sign-in-btn-home button:hover::after {
  width: 100%;
}

.sign-in-btn-home button:hover p::before {
  width: 100%;
}

.sign-in-btn-home button:hover svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.sign-in-btn-home button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.loginPart-mobile {
  background: #008080;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

.container-fluid {
  padding: 0px 35px 20px;
  margin: 0px auto;
  background-color: var(--body_background);
}

.container-fluid1-table-data {
  /* max-width: 1183px; */
  padding: 5px 35px 0px;
  margin: 0px auto;
  /* background: #f9fbfc; */
  background-color: var(--body_background);
  color: var(--body_color);
}

.Api-list-flex {
  /* width: 83em; */
  width: 100%;
  margin: auto;
  /* padding-left: 30px; */
}
.css-1nuo49t-MuiButtonBase-root-MuiTab-root {
  font-weight: 600 !important;
}

/* Media Queries */
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* @media (min-width: 1281px) {

} */
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

/* Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}
/* Landscape*/
@media only screen and (min-device-width: 1180px) and (max-device-height: 820px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .Api-list-flex {
    width: 100% !important;
  }
}
/* Landscape*/
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .Api-list-flex {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Api-list-flex {
    width: 100% !important;
  }
  .container-fluid {
    padding: 0px 20px 20px;
    margin: 0px auto;
    background-color: var(--body_background);
  }
}
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
/* @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
} */
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media screen and (max-width: 1154px) {
  .home-container {
    padding: 0rem 1rem;
  }
}

@media screen and (min-width: 300px) and (max-width: 720px) {
  .home-container {
    padding: 0rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .Api-list-flex {
    width: 100%;
    /* margin: auto; */
    /* padding-left: 30px; */
  }
  .container-fluid {
    padding: 0px 12px 20px;
    margin: 0px auto;
    background-color: var(--body_background);
  }
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media screen and (min-width: 320px) and (max-width: 480px) {
  .Api-list-flex {
    width: 100%;
    margin: auto;
    /* padding-left: 30px; */
  }
  .container-fluid1-table-data {
    /* max-width: 1183px; */
    padding: 5px 20px 110px;
  }
  .container-fluid {
    padding: 0px 20px 20px;
    margin: 0px auto;
    background-color: var(--body_background);
  }
}

@media screen and (min-width: 300px) and (max-width: 370px) {
  .container-fluid {
    padding: 0px 10px 10px !important;
    margin: 0px auto;
    background-color: var(--body_background);
  }
}
