.App {
  background-color: var(--body_background);
  font-family: 'Open Sans', sans-serif !important;
  width: 100%;
  height: 100%;
}

.cookie_link {
  font-weight: 300;
  margin: 1rem;
  font-size: 14px;
  text-decoration: unset;
  color: green;
}
