.legend-display {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 2em;
  width: 100%;
  justify-content: flex-end;

  /* padding-left: 62em; */
}

.legend-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.legend-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.legend-label {
  align-self: center;
  margin-bottom: 0px;
  padding-left: 6px;
}

/* Media Queries */
@media screen and (min-width: 1025px) and (max-width: 1200px) and (orientation: landscape) {
  .legend-display {
    /* padding-left: 47.5em !important; */
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .legend-display {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    padding-right: 24px;
    padding-left: 0em !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
  .legend-display {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    justify-content: space-around;
    gap: 1em;
    padding-left: 0em !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .legend-display {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    justify-content: space-around;
    gap: 1em;
    padding-left: 0em !important;
    float: none !important;
    width: 100%;
  }
}

@media screen and (min-width: 300px) and (max-width: 480px) {
  .legend-display {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 10px !important;
    gap: 0em 6em !important;
    width: 100% !important;
    padding: 0px !important;
  }
  .legend-container {
    width: 120px;
  }
}
