.table-loader-container {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ff;
  z-index: 1;
}

.loader-container {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ff;
  z-index: 1;
}

.loader {
  border-radius: 50%;
  border: 3px solid var(--loader_color);
  border-left-color: transparent;
  width: 50px;
  height: 50px;
  animation: spin89345 1s linear infinite;
}

/* .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 60%;
  border-top: 3px solid #00a3e0;
  border-right: 2px solid transparent;
  animation: spinner8217 0.8s linear infinite;
} */

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
