.tile-label {
  font-size: 1.5rem;
  color: #046a38;
  font-weight: bold;
}
.tile-label-dark {
  font-size: 1.5rem;
  color: #86BC25;
  font-weight: bold;
}
.tile-label-dark{
  font-size: 1.5rem;
  color: #86BC25;
  font-weight: bold;
}
.metric-numbers {
  color: #014c28;
  font-weight: bold;
  font-size: 3rem;
}
.metric-numbers-dark {
  color: #86BC25;
  font-weight: bold;
  font-size: 3rem;
}

.custom-legend {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Adjust spacing between legend items */
}

.legend-color-box {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px; /* Adjust spacing between color box and text */
}

.legend-item span {
  margin-left: 19px; /* Adjust the spacing between the square and the text */
}

.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #86bd25;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
  margin: auto;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
