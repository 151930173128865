/* Insights CSS */

.toggle-container {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 2em;
  background-color: var(--body_background);
  color: var(--body_color);
  /* padding-left: 52em; */
  /* padding-top: 10px; */
  /* margin: 0px 280px auto; */
}

.mobile-toggle {
  width: auto !important;
}

.toggle-theme {
  background-color: var(--body_background);
  gap: 56em;
  position: relative;
  /* z-index: 15; */
  /* display: flex;
  flex-direction: row; */
}
.spin-icon {
  cursor: pointer;
  color: var(--settings_icon_background);
}

.spin-icon:hover {
  animation: spin 0.8s ease-out;
  cursor: pointer;
}

@keyframes spin {
  100% {
    transform: rotate(270deg);
  }
}
.insights-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 2em;
  width: 100%;
  align-self: center;

  /* margin: 0px 280px auto; */
  background-color: var(--body_background);
  color: var(--body_color);
}

.mobile-insights {
  width: 100%;
}

.insight-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* gap: 44.5em; */
}

.service-insight-label-new {
  font-size: 32px;
}

.updates-btn {
  background: #00a3e0 !important;
  border-radius: 12px !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: var(--alert-success-container-shadow);
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: row !important;
}

.bell-image-icon {
  width: 35px;
  height: 29px;
  padding-right: 5px;
}

.updates-txt {
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 0px !important;
}

.card-alert-container {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  transition: max-Height 0.3s ease 0s;
}

.alerts-container {
  /* background: var(--alert_bg); */
  box-shadow: var(--alert_box_shadow);
}

.alerts-container-mobile {
  box-shadow: none;
}

.success-flex-alert {
  background: #86bc25;
  box-shadow: var(--alert-success-container-shadow);
  border-radius: 2px;
  place-content: center;
}

.insight-flex-alert {
  display: flex;
  gap: 26em;
  font-size: 14px;
  /* min-width: 1229px; */
  width: fit-content;
  line-height: 2em;
  flex-direction: row;
  /* background: #ffffff; */
  background-color: var(--body_background);
  box-shadow: var(--box_shadow);
  border-radius: 2px;
}
.stack-space-alerts {
  background: var(--alert_bg);
}
.services-affected-container {
  /* max-width: 82.5rem !important; */
  width: 100%;
  justify-content: flex-start;
}

.info-name {
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  gap: 1em;
  min-width: 10em;
}

.info-img-display {
  padding-bottom: 10px;
  width: 19px;
  height: 39px;
  padding-top: 10px;
  border-radius: 100%;
}

.alert-name-card {
  align-self: center;
  margin-bottom: 3px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.info-description {
  /* width: 18em; */
  /* padding-right: 0em; */
  align-self: center;
  float: left;
  text-align: center;
}

.info-timestamp {
  align-self: center;
}

.time-stamp-label {
  min-width: 10em;
  margin-bottom: 3px !important;
}

.operational-text {
  font-style: normal;
  padding-top: 7px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #ffffff;
  letter-spacing: 0.15px;
  margin-bottom: 10px !important;
}

.alert-expand {
  display: flex;
  flex-direction: row;
}

/* Media Queries */
@media screen and (min-width: 1025px) and (max-width: 1304px) {
}

@media screen and (min-width: 1920px) {
  /* .Navbar-HeaderD {
    min-height: 3vh;
  } */

  .toggle-container {
    /* padding-left: 75em; */
  }

  .insight-flex {
    /* gap: 47.2em; */
  }
}

@media screen and (min-width: 1701px) and (max-width: 1919px) {
  .Navbar-HeaderD {
    min-height: 7vh;
  }

  .toggle-container {
    /* padding-left: 75em; */
  }

  .insight-flex {
    /* gap: 47em; */
  }
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .Navbar-HeaderD {
    min-height: 3vh;
  }

  .toggle-container {
    /* padding-left: 75em; */
  }

  .insight-flex {
    /* gap: 39.5em; */
  }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  .Navbar-HeaderD {
    min-height: 7vh;
  }

  .toggle-container {
    /* padding-left: 75em; */
  }

  .insight-flex {
    /* gap: 47em; */
  }
}

/* @media screen and (min-width: 1279px) and (max-width: 1499px) {
    .Navbar-HeaderD {
        min-height: 9vh;
    }

    .toggle-container {
        padding-left: 75em;
    }

    .insight-flex {
        gap: 47em;
    }
} */

@media screen and (min-width: 1201px) and (max-width: 1278px) {
  .Navbar-HeaderD {
    min-height: 4vh;
  }

  .toggle-container {
    /* padding-left: 68em; */
  }

  .insight-flex {
    display: flex;
    /* gap: 39em; */
  }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) and (orientation: landscape) {
  .Navbar-HeaderD {
    min-height: 7vh;
  }

  .toggle-container {
    /* padding-left: 75em; */
  }

  .insight-flex {
    /* gap: 46em; */
  }
  .updates-btn {
    margin-left: auto;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1200px) and (orientation: landscape) {
  .Navbar-HeaderD {
    min-height: 7vh;
  }

  .toggle-container {
    /* padding-left: 64em; */
  }

  .insight-flex {
    /* gap: 35em; */
  }
  .updates-btn {
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .toggle-container {
    display: contents;
  }

  .insights-container {
    /* margin-left: 10px;
        margin-right: 10px; */

    display: block;
  }

  .insight-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0em;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 5px;
  }

  .service-insight-label-new {
    width: max-content;
    font-weight: 600;
    font-size: 18px;
  }

  .updates-btn {
    border-radius: 4px !important;
    box-shadow: none;
    height: 30px;
    width: 130px;
    margin-left: auto;
  }
  .updates-txt {
    font-size: 16px;
  }
  .alerts-container {
    width: 100% !important;
  }
  .alert-expand {
    justify-content: center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) {
  .insights-container {
    width: 100% !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
  .toggle-container {
    display: contents;
  }

  .insights-container {
    /* margin-left: 10px;
        margin-right: 10px; */
    width: 100% !important;
  }

  .insight-flex {
    display: flex;
    flex-direction: row;
    /* gap: 2.5em; */
  }

  .service-insight-label-new {
    width: max-content;
    font-weight: 600;
    font-size: 16px;
  }

  .updates-btn {
    border-radius: 4px !important;
    box-shadow: none;
    height: 30px;
  }

  .info-description {
    text-align: center;
  }

  .card-alert-container {
    display: block;
  }
  .insight-flex-alert {
    display: flex;
  }
}
/* Media query for mobile devices in landscape orientation */
@media screen and (max-width: 940px) and (orientation: landscape) {
  .toggle-container {
    display: contents;
  }

  .insights-container {
    /* margin-left: 10px;
        margin-right: 10px; */
    width: 100% !important;
  }

  .insight-flex {
    display: flex;
    /* gap: 8em; */
  }

  .service-insight-label-new {
    width: max-content;
    font-weight: 600;
    font-size: 16px;
  }

  .updates-btn {
    border-radius: 4px !important;
    box-shadow: none;
    height: 30px;
  }

  .info-description {
    text-align: center;
  }

  .card-alert-container {
    display: block;
    background-color: #86bc25;
  }
  .insight-flex-alert {
    display: flex;
    background-color: #86bc25;
  }
}

/* small mobile screens */
@media screen and (max-width: 330px) {
  .service-insight-label-new {
    width: max-content;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 0rem;
  }
  .insight-flex {
    align-items: center;
  }

  .updates-btn {
    border-radius: 8px !important;
    height: 25px;
  }

  .updates-txt {
    font-size: 10px !important;
  }
}
/* mobile screens */

@media screen and (min-width: 331px) and (max-width: 356px) {
  .service-insight-label-new {
    font-size: 12px !important;
  }

  .updates-txt {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 331px) and (max-width: 480px) {
  .toggle-container {
    display: contents;
  }

  .info-timestamp {
    background: var(--alert_bg);
    width: 100% !important;
  }

  .alert-name-card {
    text-align: left;
  }

  .insights-container {
    display: block;
    /*margin-left: 10px;
        margin-right: 10px;*/
    width: 100%;
  }
  .alerts-container {
    width: 100% !important;
  }
  .services-affected-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .time-stamp-label {
    margin-bottom: 0px !important;
    margin-left: 60px !important;
  }

  .info-name {
    display: flex;

    flex-direction: row;
    padding-left: 0.5rem;
    /* gap: 1em; */
    min-width: 2em;
  }

  .insight-flex {
    display: flex;
    flex-direction: row;
    /* gap: 0em; */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .service-insight-label-new {
    width: max-content;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0rem;
  }
  .updates-txt {
    font-weight: 500;
    font-size: 14px;
  }

  .updates-btn {
    border-radius: 4px !important;
    box-shadow: none;
    height: 24px;
    margin-bottom: 0rem;
  }

  .info-description {
    text-align: center;
  }

  .card-alert-container {
    display: block;
  }
}
