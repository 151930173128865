.subscription-text-area {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
  width: 520px;
  max-height: 150px;
  overflow-y: auto;
  min-height: 40px;
}

.subscription-items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.search_bar-mobile {
  padding: 0px !important;
  /* width: 360px !important; */
  /* color: var(--hour_color) !important; */
  /* border: 1px solid var(--search_bar_outline) !important; */
  border-radius: 2px !important;
}
.subscription-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 3px;
}

.subscription-name {
  flex-grow: 1;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

.remove-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}

.remove-icon :hover {
  color: rgba(0, 0, 0, 0.4);
}

.MuiAutocomplete-option {
  font-size: 14px !important;
}
.remove-all-icon {
  color: var(--search_service_label_color);
}

.update-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
