/* for light theme */
:root {
  --body_background: white;
  --body_color: rgb(0, 0, 0);
  /* --link_color: navy; */
  --border_top: 0.2px solid rgba(0, 0, 0, 0.2);
  --border_bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  --text_color: #53565a;
  --userpreference-font-color: black;
  --box_shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  --hour_color: #75787b;
  --settings_icon_background: rgb(0 0 0 / 45%);
  --for_arrow_color: black;
  --back_arrow_color: #75787b;
  --userpreference-modal-background-color: white;
  --button_border_color: 1px solid rgba(0, 0, 0, 0.4);
  --graph_bg: rgba(217, 217, 217, 0.5);
  --api_name_text_color: #00a3e0;
  --region_text_color: #75787b;
  --cloudnetwork_dropdown_border: white;
  --userpreference-img-button-hover-color: #007bff;
  --loader_color: #007cb0;
  --search_bar_outline: none;
  --userpreference-theme-image-label-color: black;
  --alert_bg: #fff;
  --alert_box_shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1) !important;
  --alert-success-container-shadow: 0px 0px 4px 1px #3932328c;
  --disable-border: 1px solid rgba(0, 0, 0, 0.2);
  --disable-border-left: 1px solid #cccccc;
  --disable-svg-fill: rgba(0, 0, 0, 0.2);
  --search_service_label_color: rgba(0, 0, 0, 0.6);
  --subscription-modal-vertical-line-height: 1px;
  --subscription-modal-box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.25),
    1px 1px 4px 4px rgba(0, 0, 0, 0.25);
  --new-subscription-service-multi-select: rgba(0, 0, 0, 0.08);
  --search_bar_dark_bg: #fff;
}
/* for dark theme */
[data-theme='dark'] {
  --body_background: #292929;
  --search_bar_dark_bg: #424242;
  --body_color: white;
  --userpreference-font-color: white;
  --userpreference-theme-image-label-color: rgba(204, 203, 203, 0.808);
  --border_top: 0.2px solid rgb(255, 255, 255);
  --border_bottom: 0.5px solid rgb(255, 255, 255);
  --text_color: white;
  --box_shadow: 0px 1px 4px rgba(204, 203, 203, 0.808);
  --userpreference-img-button-hover-color: #007bff;
  --userpreference-modal-background-color: #3c3c3c;
  --hour_color: white;
  --for_arrow_color: rgb(255, 255, 255);
  --back_arrow_color: rgb(124, 124, 124);
  --button_border_color: 1px solid rgb(124, 124, 124);
  --settings_icon_background: white;
  --graph_bg: #424242;
  --api_name_text_color: #ffffff;
  --region_text_color: white;
  --cloudnetwork_dropdown_border: white;
  --search_bar_outline: rgb(255, 255, 255);
  --loader_color: #00b4ff;
  --alert_bg: #424242;
  --alert_box_shadow: 0px 1px 4px 0px rgba(217, 217, 217, 0.5);
  --alert-success-container-shadow: 0px 0px 8px 3px rgba(255, 255, 255, 0.3);
  --disable-border: 1px solid rgb(255, 255, 255);
  --disable-border-left: 1px solid rgb(204, 204, 204);
  --disable-svg-fill: rgb(204, 204, 204);
  --search_service_label_color: white;
  --search_clear: white !important;
  --subscription-modal-vertical-line-height: 2px;
  --subscription-modal-box-shadow: 0px 1px 6px 5px rgb(255 255 255 / 25%),
    0px 4px 4px 0px rgb(195 195 195 / 25%);
  --new-subscription-service-multi-select: rgb(255, 255, 255);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(109, 108, 108, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(109, 108, 108, 0.3);
}

::-webkit-scrollbar-thumb {
  width: 3.125rem;
  background-color: #86bc25;
  border: 0.0625rem solid #86bc25;
  -webkit-border-radius: 0.375rem;
}

@media only screen and (max-width: 1024px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(109, 108, 108, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 4px !important;
    height: 7px;
    background-color: #f5f5f5;
    -webkit-box-shadow: inset 0 0 6px rgba(109, 108, 108, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    width: 3.125rem;
    background-color: #87bc252f;
    border: 0.0625rem solid #87bc25;
    -webkit-border-radius: 0.375rem;
  }
}
