.TooltipBox {
  align-content: center;
  border: none !important;
  width: 14em;
  background-color: white;
  color: black;
  margin: 1px auto;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.custom-tooltip {
  display: flex;
  flex-direction: row;
}

.TooltipLabel {
  font-size: 13px;
  margin-left: 10px;
  font-weight: 600;
}

.TooltipData {
  font-size: 13px;
  font-weight: 700;
}

.loader-graph {
  --height-of-loader: 2px;
  --loader-color: rgb(0, 163, 224);
  width: 100%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 40px;
  margin-bottom: 25px;
}

.loader-graph::before {
  content: '';
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving-new 2s ease-in-out infinite;
}

@keyframes moving-new {
  50% {
    width: 80%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

.empty-graph-data-display {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
}
