.table-cell-flex {
  display: flex !important;
  flex-direction: column;
  padding-right: 35px;
  /* border-bottom: 1px rgba(224, 224, 224, 1); */
}

.header-api {
  display: flex;
  gap: 3px;
  flex-direction: column;
}

.header-api-mobile {
  display: flex;
  gap: 3px;
  flex-direction: row;
  align-items: center;
}
.css-krgawv-MuiButtonBase-root-MuiTab-root {
  display: flex !important;
  flex-direction: row !important;
}

.image-sort {
  width: 20px !important;
  height: 20px !important;
  color: var(--body_color);
}

/* .recharts-wrapper {
  background-color: rgba(217, 217, 217, 0.5) !important ;
} */

.backward-button-scroll {
  width: 2em !important;
  border: none;
  position: absolute;
  background: transparent !important;
  color: var(--back_arrow_color);
  /* background-color: white; */
  margin-left: -32px;
  margin-top: -2px;
}

#myChart {
  display: block;
  width: 500px;
  height: 50px;
}

.forward-button-scroll {
  width: 2em !important;
  border: none;
  color: var(--for_arrow_color);
  margin-left: 63.5em;
  margin-top: -4px !important;
  position: absolute;
  background: transparent !important;
}

#column80 {
  display: flex;
  flex-direction: column;
  gap: 1em;

  /* overflow: hidden; */
  /* overflow-x: scroll; */
  scroll-behavior: smooth;
}

.button-active-text {
  font-weight: 700 !important;
  font-size: 14px !important;
  letter-spacing: 0.15px;
  line-height: 24px !important;
  border: 1px solid var(--region_text_color) !important;
  border-radius: 4px !important;
  text-transform: unset !important;
}

.button-inactive-text {
  color: var(--region_text_color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  line-height: 24px !important;
  border: 1px solid var(--region_text_color) !important;
  border-radius: 4px !important;
}

.text-date-expand {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.expand .col-10 {
  color: var(--body_color);
}

.expand-main {
  background-color: var(--body_background);
}

.text-description-expand {
  font-style: normal;
  font-weight: 400;
  width: 71%;
  font-size: 14px;
  line-height: 21px;
  padding-top: 8px;
  /* or 100% */
  color: var(--body_color);
}

.css-1ex1afd-MuiTableCell-root {
  padding: 0px !important;
}

.response-graph-background {
  background: rgba(217, 217, 217, 0.5);
  padding: 0px;
}

/* .MuiTabs-root {
  padding-left: 5px !important;
} */

.api-name-list {
  font-style: normal;
  font-weight: 600;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.15px;
  color: var(--api_name_text_color);
}

.daily-display {
  display: flex;
  justify-content: space-between;
}

.hour-display {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.response-time {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-top: 7px;
  line-height: 24px;
  /* identical to box height, or 100% */
  letter-spacing: 0.15px;
  color: var(--text_color);
}

.response-time-mobile {
  padding-left: 10px;
  font-size: 14px;
}

.average-response {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 200% */
  letter-spacing: 0.15px;
  color: var(--text_color);
}

.Button-display-tablep {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: var(--body_color) !important;
}

.Button-display-tableh {
  font-style: normal;
  /* font-weight: 400 !important; */
  font-size: 14px !important;
  line-height: 24px;
  color: var(--hour_color) !important;
}

.Button-display-table {
  font-style: normal;
  /* font-weight: 400 !important; */
  font-size: 14px !important;
  line-height: 24px;
  color: var(--hour_color) !important;
}

.row-alert-render {
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px;
}

.size-transform {
  font-size: 32px;
  text-transform: unset !important;
}

.css-13avx63-MuiButtonBase-root-MuiTab-root {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 24px;
}

.ButtonUTC {
  /* font-size: 14px !important; */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 11px !important;
  font-weight: 200 !important;
  text-transform: unset !important;
  color: var(--hour_color) !important;
  /* margin-left: 64px !important; */
}

.chatbot-container {
  background-color: var(--body_background);
}

.chatbot-logo-img {
  position: fixed;
  bottom: 25px;
  right: 50px;
}

.Tab-label-font {
  font-size: 32px !important;
  text-transform: unset !important;
}

.css-19kzrtu {
  /* padding-top: 24px; */
  background-color: var(--body_background);
}

.row-chart {
  border-top: var(--border_top);
  border-bottom: var(--border_bottom);
  padding-top: 14px;
}

.recharts-cartesian-grid-bg {
  fill: var(--graph_bg);
}

.recharts-cartesian-axis-tick-value {
  fill: var(--text_color);
}

.no-data {
  font-style: normal;
  padding-top: 40px;
  font-weight: 400;
  font-size: 20px;
  color: var(--body_color);
  text-align: center;
  min-height: 100vh;
}

/* ---------------------------------------------------------------------------------------
MEDIA QUERIES
---------------------------------------------------------------------------------------

 */

/* FULL HD SCREEN ZOOMED TO 110% */

@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .Button-display-table {
    padding: 6px 0px !important;
  }

  .forward-button-scroll {
    margin-left: 64em;
  }
}

/* FULL HD SCREENS */

@media screen and (min-width: 1920px) {
  .Button-display-table {
    padding: 6px 8px !important;
  }

  .forward-button-scroll {
    margin-left: 64em !important;
  }

  /* .insight-width {
    margin-right: 0vwv !important;
  } */
}

/* BETWEEN 720HD AND FULL HD SCREENS */

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  .css-eq2u6c-MuiStack-root > :not(style) + :not(style) {
    margin: 0 !important;
  }

  .response-time {
    font-size: 21px !important;
  }

  .hourly-daily-display {
    /* padding-left: 617px !important; */
  }

  .forward-button-scroll {
    margin-left: 53.5em !important;
    margin-top: -3px !important;
  }

  .Tab-label-font {
    font-size: 24px !important;
  }

  .row-alert-render {
    height: 17px !important;
    width: 17px !important;
  }

  .css-17uck0j-MuiStack-root > :not(style) + :not(style) {
    margin-left: 255px !important;
  }

  .health-table {
    width: 67em !important;
  }
}

.arrow-btn {
  font-size: 13px;
  max-width: 10px !important;
  background-color: rgba(128, 128, 128, 0.092);
  color: grey;
  display: flex;
  justify-content: center;
}

/* 720HD SCREENS */

@media screen and (min-width: 1279px) and (max-width: 1499px) {
  .css-eq2u6c-MuiStack-root > :not(style) + :not(style) {
    margin: 0 !important;
  }

  .response-time {
    font-size: 21px !important;
  }

  .hourly-daily-display {
    /* padding-left: 617px !important; */
  }

  .forward-button-scroll {
    margin-left: 53.5em !important;
    margin-top: -3px !important;
  }

  .Tab-label-font {
    font-size: 24px !important;
  }

  .row-alert-render {
    height: 17px !important;
    width: 17px !important;
  }

  .css-17uck0j-MuiStack-root > :not(style) + :not(style) {
    margin-left: 255px !important;
  }

  .health-table {
    width: 67em !important;
  }
}
