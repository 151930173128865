/* Global theme variables */
:root {
  --border-color: #ccc;
  --description-color: #8c8f91;
}

/* Dark Theme Variables */
body.dark-mode {
  --border-color: #555;
}

.userpreference-modal-backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center !important;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  align-items: center;
}

.userpreference-dropdown-labels {
  color: var(--userpreference-font-color);
  font-size: 14px;
}

.userpreference-image-label {
  color: var(--userpreference-theme-image-label-color);
  display: block;
  margin-top: 10px;
  font-size: 16px;
  transition: color 0.3s ease;
}

.userpreference-body {
  background-color: var(--userpreference-modal-background-color) !important;
}
.userpreference-modal-content {
  background-color: var(--userpreference-modal-background-color) !important;
  padding: 20px;
  border-radius: 8px;
  /* width: 600px; */
  outline: none;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* transition: background-color 0.3s ease; */
  transition:
    background-color 0.3s ease,
    color 0.3s ease !important;
}

.userpreference-theme-preference-header {
  margin-bottom: 5px;
  color: var(--userpreference-font-color);
}
.userpreference-description {
  font-size: 14px;
  color: var(--description-color);
}

.userpreference-section {
  margin-bottom: 40px;
}

.userpreference-section-defaultView {
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.userpreference-theme-previews {
  display: flex;
  justify-content: flex-start;
}
.lazy-load-image-background,
.lazy-load-image-loaded {
  height: 100% !important; /* Ensure the height is set to 100% */
}
.userpreference-theme-preview {
  width: 200px;
  height: auto;
  text-align: center;
  cursor: pointer;
  padding-right: 20px;
  border: none;
  background: none;
  position: relative;
  outline: none;
}

.userpreference-cancel-button:hover {
  background-color: #ff5f56;
}

.userpreference-save-button:hover {
  background-color: rgb(44, 181, 44);
}
.userpreference-theme-preview img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: transform 0.3s ease;
}

.userpreference-theme-preview.selected img {
  border: 2px solid var(--userpreference-img-button-hover-color);
}

.userpreference-theme-preview.selected .userpreference-theme-img {
  transform: scale(1.1);
}

.userpreference-select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--userpreference-modal-background-color);
  color: var(--userpreference-font-color);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.userpreference-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.userpreference-cancel-button,
.userpreference-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.userpreference-button:hover {
  background-color: var(--button-hover-color);
  color: white;
}

.userpreference-button:focus {
  outline: none;
}

.userpreference-mac-close-button {
  position: relative;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  background-color: #ff5f56;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0px !important;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
}

.userpreference-mac-close-button:hover {
  background-color: rgb(255, 117, 101);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.userpreference-mac-close-button::before {
  content: '✕'; /* This adds the 'X' mark */
  font-size: 12px;
  color: white;
  line-height: 1;
  font-weight: bold;
}

/* Tablet view (up to 768px) */
@media (max-width: 768px) {
  .userpreference-select {
    width: 100%; /* Make select full width on smaller screens */
    padding: 8px;
    font-size: 14px; /* Slightly reduce font size for smaller view */
  }
  .userpreference-modal-content {
    width: 90% !important; /* Make modal take 90% width of the mobile screen */
    padding: 15px !important;
    height: 90% !important;
  }

  .userpreference-description {
    font-size: 12px;
  }

  .userpreference-dropdown-labels {
    font-size: 12px;
  }

  .userpreference-theme-preference-header {
    font-size: 18px;
  }
}

/* Mobile view (up to 480px) */
@media (max-width: 480px) {
  .userpreference-select {
    width: 100%; /* Full width dropdown on mobile */
    padding: 6px; /* Reduce padding for better touch control */
    font-size: 12px; /* Smaller font size for compact view */
  }

  .userpreference-theme-preference-header {
    font-size: 16px;
  }

  .userpreference-save-button,
  .userpreference-cancel-button {
    padding: 8px 16px;
    width: 6rem;
    height: 2.5rem;
  }

  .userpreference-modal-content {
    width: 95% !important; /* Make modal take 90% width of the mobile screen */

    overflow: auto !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 940px) and (orientation: landscape) {
  .userpreference-select {
    width: 100%; /* Full width dropdown on mobile */
    padding: 6px; /* Reduce padding for better touch control */
    font-size: 12px; /* Smaller font size for compact view */
  }

  .userpreference-theme-preference-header {
    font-size: 16px;
  }

  .userpreference-save-button,
  .userpreference-cancel-button {
    padding: 8px 16px;
    width: 6rem;
    height: 2.5rem;
  }

  .userpreference-modal-content {
    min-height: 0% !important;
    overflow: auto !important;
    width: 90% !important; /* Make modal take 90% width of the mobile screen */
    height: 85% !important; /* Reduce height of modal */
  }
}
