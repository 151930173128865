.subscription-text-area {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  width: 520px;
  max-height: 150px;
  overflow-y: auto;
}

.subscription-items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.subscription-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 3px;
}

.subscription-name {
  flex-grow: 1;
  font-family: 'Open Sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

.remove-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}

.remove-icon :hover {
  color: rgba(0, 0, 0, 0.4);
}

.remove-all-icon {
  color: var(--search_service_label_color);
}
.update-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.update-button {
  /* padding: 8px 8px; */
  color: red;
  border: 1px solid red;
  border-radius: 16px;
  cursor: pointer;
  background-color: white;
}

.update-button[disabled] {
  opacity: 0.6; /* Reduce opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate non-interactivity */
}
