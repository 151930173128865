.Navbar-HeaderD {
  position: relative;
  min-height: 6vh;
  width: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 0px;
  z-index: 20;
}

.link-a {
  text-decoration: none;
  display: block;
  width: 85px;
}

.input-final {
  height: 24px;
  color: rgb(153, 153, 153);
  background-color: rgba(255, 255, 255, 0.2);
  max-width: 275px;
  display: inline-block;
  border-radius: 4px;
  flex: 1 1 auto;
  padding: 1em 2.5em 1em 1em;
  border: none;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
}

.name-initial-box {
  max-width: 100%;
  margin: 4px;
  /* height: 40px; */
  width: 40px;
  background-color: rgb(153, 153, 153);
}

.name-initial {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
  color: rgb(255, 255, 255);
  user-select: none;
}

.name-flex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.search-icon-new {
  text-size-adjust: 100%;
  font-size: 16px;

  font-weight: 600;
  line-height: 2em;
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  width: 1em;
  height: 1em;
  right: 1em;
  left: 95%;
  transform: translateY(-50%);
  top: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%20490%20490%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M484%20455.2L366.2%20337.4c29.2-35.6%2046.8-81.2%2046.8-130.9C413%2092.5%20320.5%200%20206.5%200%2092.4%200%200%2092.5%200%20206.5S92.4%20413%20206.5%20413c49.7%200%2095.2-17.5%20130.8-46.7L455.1%20484c8%208%2020.9%208%2028.9%200C492%20476.1%20492%20463.1%20484%20455.2zM206.5%20371.9C115.2%20371.9%2041%20297.7%2041%20206.5S115.2%2041%20206.5%2041C297.7%2041%20372%20115.3%20372%20206.5S297.7%20371.9%20206.5%20371.9z%22%2F%3E%3C%2Fsvg%3E');
}

.search-name {
  flex: 1 1 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.image-logo {
  display: block;
  height: auto;
  max-width: 100%;
}

.CMP-label {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.span-logo {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid rgb(102, 102, 102);
  white-space: nowrap;
  user-select: none;
}

.search-icon {
  padding-right: 20px;
}
