.coming-soon {
  font-style: normal;
  padding-top: 40px;
  font-weight: 400;
  font-size: 20px;
  color: var(--body_color);
  text-align: center;
  min-height: 100vh;
}

.region-dropdown-flex {
  margin: 1rem;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}

.MuiAutocomplete-popupIndicator {
  color: var(--hour_color) !important;
}

.MuiFormControl-root-MuiTextField-root label {
  color: var(--hour_color) !important;
}

#combo-box-demo {
  color: var(--hour_color) !important;
}

/* .MuiOutlinedInput-notchedOutline legend span {
  border: 1px solid var(--search_bar_outline);
} */

.search_bar {
  margin-top: 10px;
  padding: 0px !important;
  /* color: var(--hour_color) !important; */
  /* border: 1px solid var(--search_bar_outline) !important; */
  border-radius: 4px !important;
}

.device-selection {
  /* width: 113px !important ; */
  font-weight: 600 !important;
  height: 30px !important;
  font-size: 13px !important;
  border: 1px solid var(--search_bar_outline) !important;
  color: var(--region_text_color) !important;
}

.region-selection {
  /* width: 113px !important ; */
  font-weight: 600 !important;
  height: 30px !important;
  font-size: 13px !important;
  /* border: 1px solid var(--region_text_color) !important; */
  color: var(--region_text_color) !important;
  border: 1px solid var(--search_bar_outline) !important;
}

.region-dropdown-flex-mobile {
  margin: 1rem 0 1rem 0;
  flex-direction: row;
  width: auto;
}

.region-dropdown-flex-mobile-inner {
  display: flex;
  align-items: baseline;
}

.device-selection-mobile {
  width: auto;
  font-weight: 600 !important;
  height: 30px !important;
  font-size: 13px !important;
  border: 1px solid var(--search_bar_outline) !important;
  color: var(--region_text_color) !important;
}

.region-selection-mobile {
  width: auto;
  font-weight: 600 !important;
  height: 30px !important;
  font-size: 13px !important;
  border: 1px solid var(--search_bar_outline) !important;
  color: var(--region_text_color) !important;
}

.region-selection-label-mobile {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}

.device-selection-label-mobile {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}

.csp-selection-label-mobile {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0px;
}

.MuiSelect-icon {
  color: var(--region_text_color) !important;
}

.navbarLabels {
  font-size: 32px !important;
  text-transform: capitalize !important;
  margin-bottom: 0px !important;
  color: var(--body_color) !important;
}

/* .css-1thv0c3-MuiAutocomplete-root .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
   /* Change the of the clear icon for dark mode */
/* color: var(--search_clear); */

.btn-aws {
  border: 0px;
  background-color: transparent;
  border-right: 1px solid rgb(211, 208, 208);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700 !important;
  color: var(--region_text_color) !important;
}

.btn-aws-active {
  color: #00a3e0 !important;
}

.aws-btn-containers {
  padding: 0 0 3rem 0;
}

.css-j3utns {
  padding: 0px !important;
}

/* Media Queries */
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
/* @media (min-width: 1281px) {

} */
/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 1025px) and (max-width: 1199px) and (orientation: portrait) {
  .search_bar {
    width: 100% !important;
  }
}
@media (min-width: 1025px) and (max-width: 1514px) and (orientation: landscape) {
  .search_bar {
    width: 100% !important;
  }

  .region-dropdown-flex-mobile-inner {
    justify-content: space-between;
  }

  .region-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }

  .region-selection-mobile {
    font-size: 16px !important;
  }

  .device-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }

  .device-selection-mobile {
    font-size: 16px !important;
  }

  .csp-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .search_bar {
    width: 100% !important;
  }

  .region-dropdown-flex-mobile-inner {
    justify-content: space-between;
  }

  .region-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }

  .region-selection-mobile {
    font-size: 14px !important;
  }

  .device-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }

  .device-selection-mobile {
    font-size: 14px !important;
  }

  .csp-selection-label-mobile {
    font-size: 16px !important;
    margin-bottom: 1px !important;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .search_bar {
    width: 100% !important;
  }

  .region-dropdown-flex-mobile-inner {
    justify-content: space-between;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .navbarLabels {
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .btn-aws-active {
    color: #86bc25 !important;
  }

  .search_bar {
    padding: 0px !important;
    width: 100% !important;
    /* color: var(--hour_color) !important; */
    /* border: 1px solid var(--search_bar_outline) !important; */
    border-radius: 2px !important;
  }

  .region-dropdown-flex-mobile-inner {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbarLabels {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .btn-aws-active {
    color: #86bc25 !important;
  }

  .search_bar {
    padding: 0px !important;
    width: 100% !important;
    /* color: var(--hour_color) !important; */
    /* border: 1px solid var(--search_bar_outline) !important; */
    border-radius: 2px !important;
  }

  .region-dropdown-flex-mobile-inner {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}
